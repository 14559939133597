export const PATTERN_NO_PUNCTUATION = "[\\w\\s]+";

export const PATTERN_ALPHANUMERIC = "\\w+";

const emailRegExPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isUndefined = (obj) => typeof obj === "undefined";

// extract provided keys from a dict
export const extractKeyValuesFromDict = (dict, keys) =>
  keys
    ? keys.length > 0
      ? Object.fromEntries(
          Object.entries(dict).filter(([k, v]) => keys.includes(k))
        )
      : {}
    : dict;

// split an array for number of smaller arrays
export const splitArray = (array, limit = 9) => {
  if (!array) return null;
  const res = [];
  let current = 0;
  while (current < array.length) {
    const end = Math.min(array.length, current + limit);
    res.push(array.slice(current, end));
    current = end;
  }
  return res;
};

// compare if two dicts are equal by comparing values of all keys
export const areDictsEqual = (obj1, obj2, cmp = (a, b) => a === b) => {
  if (!obj1 && !obj2) return true;
  if ((!obj1 && obj2) || (obj1 && !obj2)) return false;
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
  return Object.entries(obj1).every(([k, v]) => k in obj2 && cmp(obj2[k], v));
};

export const isEmailValid = (email) =>
  email && email.match(emailRegExPattern) ? true : false;

// reference: https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const absoluteUrlRegex = /^(?:[a-z]+:)?\/\//i;

// determine if a string is an absolute url
export const isUrlAbsolute = (url) => url.search(absoluteUrlRegex) === 0;

// dataRows = list of data
// headers = [{ label, key, convertFunc }]
export const convertArrayToCsv = (dataRows, headers) => {
  const keys = headers.map((h) => h.key);
  const convertFuncs = headers.map((h) => h.convertFunc);
  let csv = headers.map((h) => h.label).join(",") + "\n";
  csv += dataRows
    .map((data) =>
      keys
        // use convertFunc if exist, otherwise simply use the raw data
        .map((k, i) => (convertFuncs[i] ? convertFuncs[i](data[k]) : data[k]))
        // combine all columns with ,
        .join(",")
    )
    // combine all rows with newline
    .join("\n");
  return csv;
};

// does not work on insecured domain (local IP)
export const copyText = (text) => navigator.clipboard.writeText(text);

export const getNameInitials = (name) =>
  name &&
  name
    .split(" ")
    .map((n) => n[0])
    .join("");

export const filterObjectNull = (obj) =>
  obj && Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));

export const getCookieByKey = (key) => {
  let name = key + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const isObjectEmpty = (objectName) =>
  objectName && Object.keys(objectName).length !== 0;

export const replaceUrlOrigin = (url, origin) => {
  if (!url || !origin) return null;
  try {
    var tmp = new URL(url);
    tmp = new URL(tmp.pathname, origin);
    return tmp.href;
  } catch (err) {
    return url;
  }
};

export const filterDeleted = (obj, flag = "deleted") =>
  obj && Object.fromEntries(Object.entries(obj).filter(([, v]) => !v[flag]));
